import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import Supplier from 'views/admin/supplier';
import { closeSupplierDialog } from '../../../redux/dialog/dialogsSlice';
import { fetchSuplieres } from '../../../redux/suplier/suplierSlice';

export default function SuppliersDialog() {
  const { dialog, suppliers } = useSelector((state: RootState) => ({
    dialog: state.dialogs.supplierDialog,
    suppliers: state.supplier.supliers,
  }));

  const { onClose, id } = dialog || {};
  const dispatch = useDispatch<any>();
  const { t, i18n } = useTranslation();

  const handleClose = useCallback(() => {
    dispatch(closeSupplierDialog());
    if (onClose) {
      onClose();
    }
  }, [onClose, dispatch]);

  useEffect(() => {
    const fetch = async () => {
      await dispatch(fetchSuplieres({ owner: id }));
    };
    if (dialog && id) {
      fetch();
    }
  }, [dispatch, dialog, id]);

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={!!dialog}
      onClose={handleClose}
      isCentered
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('dialog.suppliers.title')}</ModalHeader>
        <ModalCloseButton
          left={i18n.dir() === 'ltr' ? 'auto' : '0'}
          right={i18n.dir() === 'rtl' ? 'auto' : '0'}
        />
        <ModalBody width="100%" maxH="400px" overflowY="auto">
          {suppliers.map((supplier, key) => (
            <Supplier key={key} supplier={supplier} />
          ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
