import { useCallback, useState, useEffect, useMemo, useRef } from 'react';
import Card from 'components/card/Card';
import { useDispatch } from 'react-redux';
import { User } from 'services/@types';
import { Flex, useColorModeValue } from '@chakra-ui/react';
import {
  PaginationState,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import useColumns from './hooks/useColumns';
import BrilliantUsersTable from './brilliantUsersTable';
import UsersSearchBar from './usersSearchBar';
import UserService from '../../../../../services/user.api';

const DEFAULT_PAGE_SIZE: number = 6;
const DEFAULT_PAGE_INDEX: number = 1;

export interface RowObj {
  firstName: string;
  email: string;
  lastName: string;
  phone: string;
  roles: string;
  user_actions: string;
  id: string;
}

interface fetchUsersParams {
  name?: string;
  limit?: number;
  page?: number;
  firstName?: string;
}

const createPages = (count: number) => {
  let arrPageCount = [];

  for (let i = 1; i <= count; i++) {
    arrPageCount.push(i);
  }

  return arrPageCount;
};

function BrilliantUsersOverview() {
  const dispatch = useDispatch<any>();
  const { user } = useSelector((state: RootState) => state.auth);
  const { i18n } = useTranslation();

  // colors
  const currentUserBg = useColorModeValue('gray.100', 'gray.700');

  // states
  const [users, setUsers] = useState<User[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: DEFAULT_PAGE_INDEX,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const searchRef = useRef<HTMLInputElement | null>(null);

  const pages = useMemo(() => createPages(totalPages), [totalPages]);

  const dir = useMemo(() => i18n.dir(), [i18n]);

  // marked current user in table
  const getBg = useCallback(
    (row) => {
      if (user?.id === row?.original?.id) {
        return currentUserBg;
      }
      return null;
    },
    [user, currentUserBg],
  );

  const handleSearchClick = () => {
    setGlobalFilter(searchRef?.current?.value ?? '');
  };

  const handleChangeRowSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const {
      target: { value },
    } = e;

    setPagination((prev) => ({ ...prev, pageSize: Number(value) }));
  };

  const handleChangePageIndex = (index: number) => {
    setPagination((prev) => ({ ...prev, pageIndex: index }));
  };

  // get columns
  const columns = useColumns();

  const table = useReactTable({
    data: users,
    columns,
    state: {
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  useEffect(() => {
    const fetch = async () => {
      const params: fetchUsersParams = {
        limit: pageSize,
        page: pageIndex,
      };

      if (globalFilter) {
        params.firstName = globalFilter;
      }

      try {
        const data = await UserService.getUsers(params);
        if (data?.results) {
          setUsers(data.results as User[]);
          setTotalPages(data.totalPages);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
  }, [dispatch, pageSize, pageIndex, globalFilter]);

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
      <Card px="0px">
        <Flex
          direction="column"
          w="100%"
          overflowX={{ sm: 'scroll', lg: 'hidden' }}
        >
          <UsersSearchBar
            globalFilter={globalFilter}
            searchRef={searchRef}
            handleSearchClick={handleSearchClick}
          />
          <BrilliantUsersTable
            table={table}
            pageSize={pageSize}
            pageIndex={pageIndex}
            pages={pages}
            dir={dir}
            handleChangePageIndex={handleChangePageIndex}
            handleChangeRowSize={handleChangeRowSize}
            getBg={getBg}
          />
        </Flex>
      </Card>
    </Flex>
  );
}

export default BrilliantUsersOverview;
