import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'services/@types';

interface AppDialogProps {
  title?: string;
  content?: React.ReactNode;
  onConfirm?: () => void;
  onClose?: () => void;
  id?: string;
  user?: User | {};
  permission?: Record<string, any>;
}

interface DialogsState {
  alertDialog: undefined | AppDialogProps;
  businessDialog: undefined | AppDialogProps;
  permissionDialog: undefined | AppDialogProps;
  supplierDialog: undefined | AppDialogProps;
}

const initialState: DialogsState = {
  alertDialog: undefined,
  businessDialog: undefined,
  permissionDialog: undefined,
  supplierDialog: undefined,
};

const dialogsSlice = createSlice({
  name: 'dialogs',
  initialState,
  reducers: {
    openAlertDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.alertDialog = action.payload;
    },
    closeAlertDialog: (state) => {
      state.alertDialog = undefined;
    },
    openBusinessDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.businessDialog = action.payload;
    },
    closeBusinessDialog: (state) => {
      state.businessDialog = undefined;
    },
    openPermissionDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.permissionDialog = action.payload;
    },
    closePermissionDialog: (state) => {
      state.permissionDialog = undefined;
    },
    openSupplierDialog: (state, action: PayloadAction<AppDialogProps>) => {
      state.supplierDialog = action.payload;
    },
    closeSupplierDialog: (state) => {
      state.supplierDialog = undefined;
    },
  },
});

export const {
  openAlertDialog,
  closeAlertDialog,
  openBusinessDialog,
  closeBusinessDialog,
  openPermissionDialog,
  closePermissionDialog,
  openSupplierDialog,
  closeSupplierDialog,
} = dialogsSlice.actions;

export default dialogsSlice.reducer;
