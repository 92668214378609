import {
  Badge,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { Suplier } from 'services/@types';
import { useCallback, useMemo, useState } from 'react';
import InputField from 'components/fields/InputField';
import AuthImageContainer from 'components/authImageContainer';
import { FaUserEdit, FaCheck } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { updateSuplier } from '../../../redux/suplier/suplierSlice';
import { useTranslation } from 'react-i18next';

interface INonActiveUserProps {
  supplier: Suplier;
}
export default function Supplier(props: INonActiveUserProps) {
  const { supplier } = props;
  const dispatch = useDispatch<any>();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const brandColor = useColorModeValue('brand.500', 'brand.400');
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const toast = useToast();
  const { t } = useTranslation();

  const imgPath = useMemo(
    () => supplier?.media?.find((i: any) => i.type === 'profileImage')?.url,
    [supplier?.media],
  );

  const handleClick = useCallback(async () => {
    if (isEditable) {
      const res = await dispatch(
        updateSuplier({ businessId: supplier?.id, updates: { name } }),
      );
      if (res.payload) {
        toast({
          title: t('toast.title.success'),
          description: t('toast.description.supplier_updated'),
          status: 'success',
          duration: 3000,
        });
      }
    } else {
      setName(supplier?.name);
    }
    setIsEditable((prev) => !prev);
  }, [name, isEditable, supplier?.id, dispatch, supplier?.name]);

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      w="100%"
      gap={4}
      padding="16px"
      bg="gray.100"
      borderRadius="16px"
      transition="all 0.2s linear"
      _hover={isEditable ? {} : { bg: 'gray.200' }}
    >
      <AuthImageContainer
        path={imgPath}
        name={supplier?.name}
        h="34px"
        w="34px"
        borderRadius="50%"
      />
      <Flex direction="column" align="start" me="auto">
        {!isEditable ? (
          <>
            <Text color={textColor} fontSize="md" me="6px" fontWeight="700">
              {supplier?.name}
            </Text>
            <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">
              {supplier?.email}
            </Text>
          </>
        ) : (
          <InputField
            value={name}
            onChange={(e: any) => setName(e.target.value as string)}
            label="Supplier name"
            placeholder="Supplier name"
            fontSize="md"
            fontWeight="700"
          />
        )}
      </Flex>
      <Flex gap={2} alignItems="center">
        <Badge bg={isEditable && 'gray.200'}>
          <Icon
            as={isEditable ? FaCheck : FaUserEdit}
            color={brandColor}
            onClick={handleClick}
            transition="all 0.2s linear"
            cursor="pointer"
            w={5}
            h={5}
            _hover={{ color: 'green.200' }}
          />
        </Badge>
      </Flex>
    </Flex>
  );
}
