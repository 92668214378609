import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Suplier } from 'services/@types';
import SupplierService from 'services/supplier.api';

export interface SuplierState {
  supliers: Suplier[];
  suplier: Suplier | null;
  loading: boolean;
  error: string | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
}

const initialState: SuplierState = {
  supliers: [],
  suplier: null,
  loading: false,
  error: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
};

export const fetchSuplieres = createAsyncThunk(
  'suplier/fetchSuplieres',
  async (params: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await SupplierService.getSuplieres(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createSuplier = createAsyncThunk(
  'suplier/createSuplier',
  async (suplier: Suplier, { rejectWithValue }) => {
    try {
      const newSuplier = await SupplierService.createSuplier(suplier);
      console.log('#Redux create suplier ->', newSuplier);

      return newSuplier;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateSuplier = createAsyncThunk(
  'suplier/updateSuplier',
  async (
    { businessId, updates }: { businessId: string; updates: Suplier },
    { rejectWithValue },
  ) => {
    try {
      const response = await SupplierService.updateSuplier(businessId, updates);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteSuplier = createAsyncThunk(
  'suplier/deleteSuplier',
  async (businessId: string, { rejectWithValue }) => {
    try {
      await SupplierService.deleteSuplier(businessId);
      return businessId;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const suplierSlice = createSlice({
  name: 'suplier',
  initialState,
  reducers: {
    setSuplier: (state, action) => {
      state.suplier = action.payload;
    },
    resetSuplier: (state) => {
      state.suplier = null;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSuplieres.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSuplieres.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.supliers = action.payload.results;
        state.pagination = {
          page: action.payload.page,
          limit: action.payload.limit,
          totalPages: action.payload.totalPages,
          totalResults: action.payload.totalResults,
        };
      })
      .addCase(fetchSuplieres.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(createSuplier.fulfilled, (state, action) => {
        state.supliers.unshift(action.payload);
      })
      .addCase(updateSuplier.fulfilled, (state, action) => {
        const index = state.supliers.findIndex(
          (suplier) => suplier.id === action.payload.id,
        );
        state.supliers[index] = action.payload;
      })
      .addCase(deleteSuplier.fulfilled, (state, action) => {
        state.supliers = state.supliers.filter(
          (suplier) => suplier.id !== action.payload,
        );
      });
  },
});

export const { setSuplier, resetSuplier, setPagination } = suplierSlice.actions;

export default suplierSlice.reducer;
