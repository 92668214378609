import { Text } from '@chakra-ui/react';
import Card from 'components/card/Card';
import PieChart from 'components/charts/PieChart';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { businessByCategory } from 'services/@types';

interface BusinessByCategoryCardProps {
  businessesByCategory: businessByCategory[];
}
export default function BusinessByCategoryCard(
  props: BusinessByCategoryCardProps,
) {
  const { businessesByCategory } = props;
  const { t } = useTranslation();

  const chartOptions = useMemo(
    () => ({
      labels: businessesByCategory.map((item) =>
        t(`business_tags.${item.name}`),
      ),
      dataLabels: {
        enabled: false,
      },
      legend: {
        markers: {
          offsetX: 1,
          offsetY: 2,
        },
      },
    }),

    [businessesByCategory, t],
  );

  const chartData = useMemo(
    () => businessesByCategory.map((item) => item.businesses),
    [businessesByCategory],
  );
  return (
    <Card gap={2} flex="1" maxW="410px" minW={{ base: '100%', lg: 'xl' }}>
      <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">
        {t('stats.businesses_by_category')}
      </Text>
      <PieChart
        chartOptions={chartOptions}
        chartData={chartData}
        height="300px"
      />
    </Card>
  );
}
