import { Badge, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { Invite, NonActiveUserData } from 'services/@types';
import { MdEmail } from 'react-icons/md';
import useFormatDate from 'utils/hooks/useFormatDate';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import useFetchUserById from '../hooks/useFetchUserById';
import AuthImageContainer from 'components/authImageContainer';

interface INonActiveUserProps {
  invite: Invite;
}
export default function PendingInvite(props: INonActiveUserProps) {
  const { invite } = props;
  const lastLogined = useFormatDate(invite?.createdAt);
  const userId = useMemo(() => invite.senderID, [invite.senderID]);
  const { userInfo, fullName, imgPath } = useFetchUserById(userId);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const navigate = useNavigate();
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      w="100%"
      gap={4}
      padding="16px"
      bg="gray.100"
      borderRadius="16px"
      transition="all 0.2s linear"
      _hover={{ bg: 'gray.200' }}
    >
      {invite?.email && (
        <Badge
          fontSize="sm"
          cursor="pointer"
          border="1px solid transparent"
          transition="border 0.2s linear"
          _hover={{ border: '1px solid green' }}
          onClick={() => window.open(`mailto:${invite.email}`)}
        >
          <Icon as={MdEmail} color="blue.500" />
        </Badge>
      )}
      <Flex direction="column" align="start" me="auto">
        <Text color={textColor} fontSize="md" me="6px" fontWeight="700">
          {invite?.fullName}
        </Text>
        <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">
          {lastLogined}
        </Text>
      </Flex>
      <Flex gap={2} alignItems="center">
        {userInfo && (
          <Badge
            fontSize="sm"
            cursor="pointer"
            border="1px solid transparent"
            transition="border 0.2s linear"
            bg="gray.300"
            _hover={{ border: '1px solid green', bg: 'white' }}
            display="flex"
            alignItems="center"
            gap={2}
            onClick={() =>
              navigate(`/admin/brilliant_users/update_user/${userInfo.id}`)
            }
          >
            <AuthImageContainer
              path={imgPath}
              name={fullName}
              w="50px"
              h="50px"
              borderRadius={50}
            />
            <Text
              color={textColor}
              fontSize="md"
              me="6px"
              fontWeight="700"
              cursor="pointer"
            >
              {fullName}
            </Text>
          </Badge>
        )}
      </Flex>
    </Flex>
  );
}
