import { Badge, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import AuthImageContainer from 'components/authImageContainer';
import { useMemo } from 'react';
import { NonActiveUserData } from 'services/@types';
import useFetchUserById from '../hooks/useFetchUserById';
import { MdEmail } from 'react-icons/md';
import { MdContactPhone } from 'react-icons/md';
import useFormatDate from 'utils/hooks/useFormatDate';
import { useNavigate } from 'react-router-dom';

interface INonActiveUserProps {
  user: NonActiveUserData;
}
export default function NonActiveUser(props: INonActiveUserProps) {
  const { user } = props;
  const navigate = useNavigate();
  const userId = useMemo(() => user.userId, [user.userId]);
  const { userInfo, fullName, imgPath } = useFetchUserById(userId);
  const lastLogined = useFormatDate(user?.timestamp);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  if (!userInfo) return null;
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      w="100%"
      gap={4}
      padding="16px"
      bg="gray.100"
      borderRadius="16px"
      transition="all 0.2s linear"
      _hover={{ bg: 'gray.200' }}
    >
      <AuthImageContainer path={imgPath} name={fullName} />
      <Flex direction="column" align="start" me="auto">
        <Text
          color={textColor}
          fontSize="md"
          me="6px"
          fontWeight="700"
          cursor="pointer"
          onClick={() =>
            navigate(`/admin/brilliant_users/update_user/${userId}`)
          }
        >
          {fullName}
        </Text>
        <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">
          {lastLogined}
        </Text>
      </Flex>
      <Flex gap={2} alignItems="center">
        {userInfo?.email && (
          <Badge
            fontSize="sm"
            cursor="pointer"
            border="1px solid transparent"
            transition="border 0.2s linear"
            _hover={{ border: '1px solid green' }}
            onClick={() => window.open(`mailto:${userInfo.email}`)}
          >
            <Icon as={MdEmail} color="blue.500" />
          </Badge>
        )}
        {userInfo?.phone && (
          <Badge
            fontSize="sm"
            cursor="pointer"
            border="1px solid transparent"
            transition="border 0.2s linear"
            _hover={{ border: '1px solid green' }}
            onClick={() => window.open(`tel:${userInfo.phone}`)}
          >
            <Icon as={MdContactPhone} color="green.500" />
          </Badge>
        )}
      </Flex>
    </Flex>
  );
}
