import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getNonActiveUsers,
  getPendingInvites,
  setNonActiveParams,
  setPendingInvitesParams,
} from '../../../../../redux/stats/statsSlice';
import { RootState } from 'redux/store';
import { createPages } from 'utils/createPages';
import { fetchFilteredItems } from '../../../../../redux/filterItem/filterItemsSlice';

export default function useFilteredItems(
  _route: string,
  _filter: Record<string, any>,
) {
  const { error, filter, loading, pagination, route } = useSelector(
    (state: RootState) => state.filterItems,
  );

  const dispatch = useDispatch<any>();

  const handleChangeParams = useCallback(
    (value: number, key: string) => {
      const newFilter = {
        ..._filter,
        ...filter,
        page: pagination.page,
        limit: pagination.limit,
        [key]: value,
      };
      dispatch(fetchFilteredItems({ route: _route, filter: newFilter }));
    },
    [dispatch, filter, pagination, _route, _filter],
  );

  useEffect(() => {
    dispatch(fetchFilteredItems({ route: _route, filter: _filter }));
  }, [dispatch, _route, _filter]);

  return {
    handleChangeParams,
    error,
    loading,
    filter,
    pagination,
    route,
  };
}
