import { createColumnHelper } from '@tanstack/react-table';
import { Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaUserEdit } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { openSupplierDialog } from '../../../../../../redux/dialog/dialogsSlice';

export interface RowObj {
  firstName?: string;
  id: string;
  _id: string;
  lastName: string;
  email: string;
}

const columnHelper = createColumnHelper<RowObj>();

export default function useColumns(): any {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const textColor = useColorModeValue('navy.700', 'white');
  const brandColor = useColorModeValue('brand.500', 'brand.400');
  const dispatch = useDispatch<any>();

  const handleOpenSuppliersDialog = (id: string) => {
    // open suppliers dialog
    dispatch(openSupplierDialog({ id }));
  };

  return [
    columnHelper.accessor('email', {
      id: 'email',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('business_table.email')}
        </Text>
      ),
      cell: (info) => (
        <Text
          color={textColor}
          fontSize="md"
          fontWeight="500"
          onClick={() => {
            handleOpenSuppliersDialog(info.row.original._id);
          }}
          cursor="pointer"
          _hover={{ textDecoration: 'underline' }}
          transition="all 0.4s linear"
        >
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('firstName', {
      id: 'name',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('user_table.firstName')}
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('lastName', {
      id: 'category',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          {t('user_table.lastName')}
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="md" fontWeight="500">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('id', {
      id: 'id',
      header: () => {},
      cell: (info) => (
        <Flex gap={5}>
          <Icon
            as={FaUserEdit}
            color={brandColor}
            onClick={() => {
              navigate(
                `/admin/brilliant_users/update_user/${info.row.original.id}`,
              );
            }}
            transition="all 0.2s linear"
            cursor="pointer"
            _hover={{ transform: 'scale(1.2)' }}
          />
        </Flex>
      ),
    }),
  ];
}
