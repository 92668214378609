import { Button, Flex, Icon, Select, Stack, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { HSeparator } from 'components/separator/Separator';
import { useTranslation } from 'react-i18next';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { Invite, NonActiveUserData } from 'services/@types';
import useFilteredItems from './hooks/useFilteredItems';
import NonActiveUser from './nonActiveUser';
import PendingInvite from './pendingInvite';
const DAYS_AGO: number[] = [7, 30, 90];
const DEFAULT_PAGE_SIZES: number[] = [6, 7, 8, 9, 10];
export default function NonActiveUsers({
  route,
  filter,
  title,
}: {
  route: string;
  filter: Record<string, any>;
  title: string;
}) {
  const { t } = useTranslation();
  const {
    pagination: { page, totalPages, limit, results },
    handleChangeParams,
  } = useFilteredItems(route, filter);
  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
      <Card gap={2}>
        <Flex justifyContent="space-between" alignItems="center" gap={2}>
          <Text fontSize="xl" fontWeight="bold">
            {t(title)}
          </Text>
          {route === 'non-active-users' && (
            <Select
              maxW="150px"
              textAlign="center"
              name="daysAgo"
              onChange={(e) =>
                handleChangeParams(Number(e.target.value), e.target.name)
              }
            >
              {DAYS_AGO.map((day) => (
                <option key={day} value={day}>
                  {`${day} ${t('non_active_users.days_ago')}`}
                </option>
              ))}
            </Select>
          )}
        </Flex>
        <HSeparator />
        <Flex direction="column" gap={3}>
          {results.length === 0 && (
            <Text textAlign="center" color="rgba(135, 140, 189, 0.3)">
              {t('non_active_users.no_non_active_users')}
            </Text>
          )}
          {results.map((user, key) => {
            if (route === 'non-active-users') {
              return (
                <NonActiveUser key={key} user={user as NonActiveUserData} />
              );
            } else {
              return <PendingInvite key={key} invite={user as Invite} />;
            }
          })}
        </Flex>
        {results.length !== 0 && (
          <Flex alignItems="center" justify="space-between">
            <Select
              fontSize="sm"
              textAlign="center"
              variant="main"
              value={limit}
              name="limit"
              onChange={(e) =>
                handleChangeParams(Number(e.target.value), e.target.name)
              }
              maxW="100px"
            >
              {DEFAULT_PAGE_SIZES.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>
            <Flex flex="1">
              <Stack
                direction="row"
                alignSelf="flex-end"
                spacing="4px"
                ms="auto"
              >
                <Button
                  onClick={() => handleChangeParams(page - 1, 'page')}
                  disabled={page === 1}
                  transition="all .5s ease"
                  w="40px"
                  h="40px"
                  variant="no-effects"
                  borderRadius="50%"
                  bg="transparent"
                  border="1px solid"
                  borderColor={'blackAlpha.100'}
                  _hover={{
                    bg: 'whiteAlpha.100',
                    opacity: '0.7',
                  }}
                >
                  <Icon as={MdChevronLeft} w="16px" h="16px" color="gray" />
                </Button>
                <Flex
                  maxW={{ base: '100px', lg: '500px' }}
                  overflowX="auto"
                  css={{
                    '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                    '&::-webkit-scrollbar-track': {
                      display: 'none',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      display: 'none',
                    },
                  }}
                >
                  {Array.from({ length: totalPages }).map((_, index) => {
                    return (
                      <Button
                        variant="no-effects"
                        transition="all .5s ease"
                        onClick={() => handleChangeParams(index + 1, 'page')}
                        w="40px"
                        h="40px"
                        borderRadius="50%"
                        bg={index + 1 === page ? 'green' : 'transparent'}
                        border={
                          index + 1 === page ? 'none' : '1px solid lightgray'
                        }
                        _hover={
                          index + 1 === page
                            ? {
                                opacity: '0.7',
                              }
                            : {
                                bg: 'whiteAlpha.100',
                              }
                        }
                        key={index + 1}
                      >
                        <Text
                          fontSize="sm"
                          color={index + 1 === page ? '#fff' : 'red'}
                        >
                          {index + 1}
                        </Text>
                      </Button>
                    );
                  })}
                </Flex>

                <Button
                  onClick={() => handleChangeParams(page + 1, 'page')}
                  disabled={page === totalPages}
                  transition="all .5s ease"
                  w="40px"
                  h="40px"
                  variant="no-effects"
                  borderRadius="50%"
                  bg="transparent"
                  border="1px solid"
                  borderColor={'blackAlpha.100'}
                  _hover={{
                    bg: 'whiteAlpha.100',
                    opacity: '0.7',
                  }}
                >
                  <Icon as={MdChevronRight} w="16px" h="16px" color="gray" />
                </Button>
              </Stack>
            </Flex>
          </Flex>
        )}
      </Card>
    </Flex>
  );
}
