import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import ActiveUsersCard from './activeUsersCard';
import BusinessByCategoryCard from './businessesByCategoryCard';
import useStats from './hooks/useStats';
import StatCard from './StatCard';

function Stats() {
  const { stats, businessesByCategory } = useStats();
  const { t } = useTranslation();
  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }} gap={4}>
      <Flex gap={2} wrap="wrap">
        <StatCard
          value={stats.totalBusinesses}
          label={t('stats.total_businesses')}
          bg="brand.500"
          flex="1"
        />
        <StatCard
          value={stats.monthlyRegisteredBusinesses}
          label={t('stats.monthly_registered_businesses')}
          flex="1"
          bg="brand.500"
        />
        <StatCard
          value={stats.dailyActiveUsers}
          label={t('stats.daily_active_users')}
          bg="brand.500"
          flex="1"
        />
        <StatCard
          value={stats.weeklyActiveUsers}
          label={t('stats.weekly_active_users')}
          bg="brand.500"
          flex="1"
        />
        <StatCard
          value={stats.monthlyActiveUsers}
          label={t('stats.monthly_active_users')}
          bg="brand.500"
          flex="1"
        />
        <StatCard
          value={stats.invitedSuppliers}
          label={t('stats.invated_suppliers')}
          bg="brand.500"
          flex="1"
        />
        <StatCard
          value={stats.registeredSuppliers}
          label={t('stats.registered_suppliers')}
          bg="brand.500"
          flex="1"
        />
      </Flex>
      <Flex wrap="wrap" gap={2}>
        <BusinessByCategoryCard businessesByCategory={businessesByCategory} />
        <ActiveUsersCard />
      </Flex>
    </Flex>
  );
}

export default Stats;
