import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import {} from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import RTLLayout from './layouts/rtl';
import './config/i18n/config';
import {
  ChakraProvider,
  // extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useState } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import ProtectedRoute from 'components/ProtectedRoute';
// dialogs
import AlertDialog from 'components/dialogs/alertDialog';
import BusinessDialog from 'components/dialogs/businessDialog';
import PermissionDialog from 'components/dialogs/permissionDialog';
import SuppliersDialog from 'components/dialogs/suppliersDialog';

export default function Main() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  return (
    <Provider store={store}>
      <ChakraProvider theme={currentTheme}>
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route
              path="admin/*"
              element={
                <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
              }
            />
            <Route
              path="rtl/*"
              element={
                <RTLLayout theme={currentTheme} setTheme={setCurrentTheme} />
              }
            />
            <Route path="/" element={<Navigate to="/admin" replace />} />
          </Route>
          <Route path="auth/*" element={<AuthLayout />} />
        </Routes>
        <AlertDialog />
        <BusinessDialog />
        <PermissionDialog />
        <SuppliersDialog />
      </ChakraProvider>
    </Provider>
  );
}
