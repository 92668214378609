import { useEffect, useMemo, useState } from 'react';
import { User } from 'services/@types';
import UserServece from 'services/user.api';

export default function useFetchUserById(id: string) {
  const [userInfo, setUserInfo] = useState<User | null>(null);

  const fullName = useMemo(
    () => userInfo?.firstName + ' ' + userInfo?.lastName,
    [userInfo?.firstName, userInfo?.lastName],
  );

  const imgPath = useMemo(
    () =>
      userInfo?.media?.find(
        (i: any) => i.type === 'profileImage' || i.type === 'avatar',
      )?.url,
    [userInfo?.media],
  );

  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (!id) {
          return;
        }
        const date = await UserServece.getUser(id);
        if (date) {
          setUserInfo(date);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, [id]);
  return { userInfo, fullName, imgPath };
}
