import axios, { AxiosInstance, AxiosResponse } from 'axios';
import {
  activeUserPerDay,
  activeUsersResult,
  IPagination,
  NonActiveUsersParams,
  NonActiveUsersResults,
  StatsResults,
} from './@types';
import AuthServices from './auth.api';
class StatsService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Add an interceptor to attach the token to each request
    this.api.interceptors.request.use((config) => {
      const _token = localStorage.getItem('token');
      const token = _token ? JSON.parse(_token).tokens.access.token : null;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });
    // Add an interceptor which detect 401 status code
    this.api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          AuthServices.logout();
        }

        return Promise.reject(error);
      },
    );
  }

  public async getStats(): Promise<StatsResults> {
    const response: AxiosResponse<StatsResults> = await this.api.get('/stats');
    return response.data;
  }

  public async getActiveUsers(daysAgo: number): Promise<activeUserPerDay[]> {
    const response: AxiosResponse<activeUsersResult> = await this.api.get(
      '/stats/active-users',
      {
        params: {
          daysAgo,
        },
      },
    );
    return response.data.activeUsers;
  }
  public async getNonActiveUsers(
    params: Record<string, any>,
  ): Promise<IPagination> {
    const response: AxiosResponse<IPagination> = await this.api.get(
      '/stats/non-active-users',
      {
        params,
      },
    );
    return response.data;
  }
  public async getPendingInvites(
    params: Omit<NonActiveUsersParams, 'daysAgo'>,
  ): Promise<NonActiveUsersResults> {
    const response: AxiosResponse<NonActiveUsersResults> = await this.api.get(
      '/stats/pending-invites',
      {
        params,
      },
    );
    return response.data;
  }
}

const _statsService = new StatsService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _statsService;
