// src/features/users/userSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { User, IPagination, Business } from 'services/@types';
import UserService from 'services/user.api';
export interface UserState {
  users: User[];
  user: User | null;
  loading: boolean;
  error: string | null;
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
  };
  selectedBusiness: Business | null;
  updatedUserValues: Partial<User> | null;
  usersWithSuppliers: IPagination;
}

const initialState: UserState = {
  users: [],
  user: null,
  loading: false,
  error: null,
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
  },
  selectedBusiness: null,
  updatedUserValues: null,
  usersWithSuppliers: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
    results: [],
  },
};

export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async (params: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await UserService.getUsers(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchUser = createAsyncThunk(
  'users/fetchUser',
  async (userId: string, { rejectWithValue }) => {
    try {
      const response = await UserService.getUser(userId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createUser = createAsyncThunk(
  'users/createUser',
  async (user: User, { rejectWithValue }) => {
    try {
      const response = await UserService.createUser(user);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (
    { userId, updates }: { userId: string; updates: Partial<User> },
    { rejectWithValue },
  ) => {
    try {
      const response = await UserService.updateUser(userId, updates);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (userId: string, { rejectWithValue }) => {
    try {
      await UserService.deleteUser(userId);
      return userId;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateSelectedUser = createAsyncThunk(
  'users/updateSelectedUser',
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        users: { updatedUserValues, user },
      } = getState() as RootState;
      const response = await UserService.updateUser(
        user?.id,
        updatedUserValues,
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchUsersWithSuppliers = createAsyncThunk(
  'users/fetchUsersWithSuppliers',
  async (params: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await UserService.getUsersWithSuppliers(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setSelectedBusiness(state, action: PayloadAction<Business>) {
      state.selectedBusiness = action.payload;
    },
    setUpdatedUserValues(state, action: PayloadAction<Partial<User>>) {
      state.updatedUserValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch all users
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchUsers.fulfilled,
        (state, action: PayloadAction<IPagination>) => {
          state.users = action.payload.results as User[];
          state.pagination.page = action.payload.page;
          state.pagination.limit = action.payload.limit;
          state.pagination.totalPages = action.payload.totalPages;
          state.pagination.totalResults = action.payload.totalResults;
          state.loading = false;
        },
      )
      .addCase(fetchUsers.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Fetch a single user
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action: PayloadAction<User>) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(fetchUser.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Create a user
      .addCase(createUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createUser.fulfilled, (state, action: PayloadAction<User>) => {
        state.users.push(action.payload);
        state.loading = false;
      })
      .addCase(createUser.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update a user
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action: PayloadAction<User>) => {
        const index = state.users.findIndex(
          (user) => user.id === action.payload.id,
        );
        if (index !== -1) {
          state.users[index] = action.payload;
        }
        state.loading = false;
      })
      .addCase(updateUser.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete a user
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state, action: PayloadAction<string>) => {
        state.users = state.users.filter((user) => user.id !== action.payload);
        state.loading = false;
      })
      .addCase(deleteUser.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateSelectedUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateSelectedUser.fulfilled,
        (state, action: PayloadAction<User>) => {
          state.user = action.payload;
          state.loading = false;
        },
      )
      .addCase(
        updateSelectedUser.rejected,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.error = action.payload;
        },
      )
      .addCase(fetchUsersWithSuppliers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsersWithSuppliers.fulfilled, (state, action) => {
        state.usersWithSuppliers = action.payload;
        state.loading = false;
      })
      .addCase(fetchUsersWithSuppliers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setSelectedBusiness, setUpdatedUserValues } = userSlice.actions;
export default userSlice.reducer;
