// src/features/businesses/userSlice.ts
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import BusinessService from 'services/business.api';
import InvitesService from 'services/invites.api';
import SupplierService from 'services/supplier.api';
import UserService from 'services/user.api';
import StatsService from 'services/stats.api';

export interface FilterItemState {
  pagination: {
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
    results?: any[];
  };
  filter: Record<string, any>;
  route: string;
  loading: boolean;
  error: string | null;
}

const initialState: FilterItemState = {
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 1,
    totalResults: 0,
    results: [],
  },
  filter: {},
  route: '',
  loading: false,
  error: null,
};

export const fetchFilteredItems = createAsyncThunk(
  'filterItems/fetchFilteredItems',
  async (
    params: { route: string; filter: Record<string, any> },
    { rejectWithValue },
  ) => {
    try {
      let response;
      switch (params.route) {
        case 'non-active-users':
          response = await StatsService.getNonActiveUsers(params.filter);
          break;
        case 'users':
          response = await UserService.getUsers(params.filter);
          break;
        case 'businesses':
          response = await BusinessService.getBusinesses(params.filter);
          break;
        case 'suppliers':
          response = await SupplierService.getSuplieres(params.filter);
          break;
        case 'invites':
          response = await InvitesService.getInvites(params.filter);
          break;
        default:
          break;
      }
      return { response, params, route: params.route };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const filterItemsSlice = createSlice({
  name: 'filterItems',
  initialState,
  reducers: {
    setPagitation: (state, action) => {
      state.pagination = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilteredItems.fulfilled, (state, action) => {
        state.pagination = action.payload.response;
        state.filter = action.payload.params.filter;
        state.route = action.payload.route;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchFilteredItems.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchFilteredItems.pending, (state) => {
        state.loading = true;
        state.error = null;
      });
  },
});

export default filterItemsSlice.reducer;
