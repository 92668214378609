import { Flex } from '@chakra-ui/react';
import {
  PaginationState,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { fetchUsersWithSuppliers } from '../../../../../redux/user/userSlice';
import { createPages } from 'utils/createPages';
import useColumns from './hooks/useColumns';
import SuppliersSearchBar from './suppliersSearchBard';
import SuppliersTable from './suppliersTable';
import { User } from 'services/@types';
export default function UsersWithSuppliers() {
  const dispatch = useDispatch<any>();
  const { i18n } = useTranslation();
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const searchRef = useRef<HTMLInputElement | null>(null);
  const { results, limit, page, totalPages, totalResults } = useSelector(
    (state: RootState) => state.users.usersWithSuppliers,
  );

  const dir = useMemo(() => i18n.dir(), [i18n]);

  const pages = useMemo(() => createPages(totalPages), [totalPages]);

  const handleSearchClick = useCallback(async () => {
    setGlobalFilter(searchRef?.current?.value ?? '');
    const params: { limit: number; page: number; searchQuery?: string } = {
      limit,
      page,
    };
    if (searchRef?.current?.value) {
      params['searchQuery'] = searchRef?.current?.value;
    }
    await dispatch(fetchUsersWithSuppliers(params));
  }, [limit, dispatch, searchRef?.current?.value, page]);

  const handleChangePageIndex = async (index: number) => {
    await dispatch(fetchUsersWithSuppliers({ page: index }));
  };

  const handleChangeRowSize = async (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const {
      target: { value },
    } = e;

    await dispatch(fetchUsersWithSuppliers({ limit: Number(value) }));
  };

  const columns = useColumns();

  const table = useReactTable({
    data: results as User[],
    columns,
    state: {
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  useEffect(() => {
    const getSupliers = async () => {
      await dispatch(fetchUsersWithSuppliers({}));
    };
    getSupliers();
  }, [dispatch]);

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
      <Card px="0px">
        <Flex
          direction="column"
          w="100%"
          overflowX={{ sm: 'scroll', lg: 'hidden' }}
        >
          <SuppliersSearchBar
            globalFilter={globalFilter}
            searchRef={searchRef}
            handleSearchClick={handleSearchClick}
          />
          <SuppliersTable
            table={table}
            limit={limit}
            pageIndex={page}
            pages={pages}
            dir={dir}
            handleChangePageIndex={handleChangePageIndex}
            handleChangeRowSize={handleChangeRowSize}
          />
        </Flex>
      </Card>
    </Flex>
  );
}
